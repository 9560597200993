<!--
	@name app-layout-account-header
	@description Header for account page layout
	@date 2020/02/21
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<div :component="$options.name">
		<v-app-bar app dense :dark="toolbarDark" :color="toolbarColour" clipped-left>
			<v-img v-if="displayLogo && toolbarLogo" max-width="2rem" class="mr-5" :src="toolbarLogo | urlize('brand')" />
			<v-toolbar-title v-if="user" class="text-subtitle-2">
				<v-btn
					v-if="permission('ui.account.impersonate.system').read || impersonator.id"
					elevation="0"
					height="32"
					:text="!impersonator.id"
					:outlined="!impersonator.id"
					:color="impersonator.id ? 'accent' : 'black'"
					class="px-3 text-none text-subtitle-2"
					:class="impersonator.id ? 'black--text' : ''"
					@click="openAccountTakeover()"
				>
					<v-icon v-if="impersonator.id" left color="error">
						mdi-close-circle
					</v-icon>
					{{ impersonator.id ? 'Viewing as:' : 'Logged in as' }} {{ user.name }}
				</v-btn>
				<span v-else>
					{{ user.name }}
				</span>
			</v-toolbar-title>

			<v-spacer />

			<div v-if="site.tradingStylePanels && organisation.organisationTradingStyles?.length && (!permission('ui.account.impersonate.system').read || impersonator.id)">
				<v-fade-transition>
					<v-btn outlined class="mr-2 font-weight-regular text-none grey lighten-2 black--text border-animation" @click="openSelectTradingStyleClick">
						Select Trading Style<span v-if="userTradingStyle">: {{ userTradingStyle.name }}</span>
					</v-btn>
				</v-fade-transition>
			</div>
			<div v-else>
				<v-tooltip v-if="showMembershipTier" bottom max-width="300">
					<template #activator="{ on }">
						<v-fade-transition>
							<v-btn
								outlined
								class="mr-2 font-weight-regular text-none grey lighten-2 black--text border-animation"
								:disabled="tierDisplayDisabled"
								v-on="on"
								@click="openTierDialog"
							>
								<template v-if="toolbarTier.membership">
									Membership tier: <span class="font-weight-bold">{{ toolbarTier.membership }}</span>
								</template>
								<template v-else>
									Join Air Club for <span class="font-weight-bold">FREE</span> today!
								</template>
								<v-divider v-if="toolbarTier.icon" vertical class="mx-2" />
								<v-icon v-if="toolbarTier.icon" :color="toolbarTier.colour" size="18">
									{{ toolbarTier.icon }}
								</v-icon>
							</v-btn>
						</v-fade-transition>
					</template>
					<span class="text-center">{{ toolbarTier.tooltip }}</span>
				</v-tooltip>
			</div>

			<v-tooltip v-if="showContact" bottom>
				<template #activator="{ on }">
					<v-btn icon v-on="on" @click="openContactBox">
						<v-icon :class="iconClass">
							mdi-phone
						</v-icon>
					</v-btn>
				</template>
				<span>Contact Us</span>
			</v-tooltip>

			<v-tooltip v-if="showAccount" bottom>
				<template #activator="{ on }">
					<v-btn icon to="/settings" v-on="on">
						<v-icon :class="iconClass">
							mdi-account
						</v-icon>
					</v-btn>
				</template>
				<span>My Account</span>
			</v-tooltip>

			<v-tooltip v-if="showLogout" bottom>
				<template #activator="{ on }">
					<v-btn icon v-on="on" @click="openLogout">
						<v-icon :class="iconClass">
							mdi-logout
						</v-icon>
					</v-btn>
				</template>
				<span>Log out</span>
			</v-tooltip>
		</v-app-bar>
		<common-dialog-confirm ref="confirm" />
		<app-dialog-contact ref="contact" />
		<app-dialog-air-tier ref="tier" />
		<app-dialog-account-takeover ref="impersonation" />
		<common-dialog-confirm ref="exitImpersonation" />
		<app-dialog-select-trading-style v-if="!isAdmin" ref="selectTradingStyle" />
	</div>
</template>

<script>
	import { debounce } from 'lodash';
	import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
	import { EventBus } from '@/utils';
	import CommonDialogConfirm from '@/component/common/dialog/confirm';
	import AppDialogContact from '@/component/app/dialog/contact';
	import AppDialogAirTier from '@/component/app/dialog/air-tier';
	import AppDialogAccountTakeover from '@/component/app/dialog/account-takeover';
	import AppDialogSelectTradingStyle from '@/component/app/dialog/select-trading-style';

	export default {
		name: 'app-layout-account-header',

		components: { CommonDialogConfirm, AppDialogContact, AppDialogAirTier, AppDialogAccountTakeover, AppDialogSelectTradingStyle },

		props: {
			showContact: { type: Boolean, default: true },
			showAccount: { type: Boolean, default: true },
			showLogout: { type: Boolean, default: true },
			factfind: { type: Boolean, default: false }
		},

		data() {
			return {
				tierDisplayDisabled: false
			};
		},

		computed: {
			...mapState('CmsSite', ['site']),
			...mapState('Account', ['user', 'permission', 'impersonator', 'organisation']),
			...mapGetters('Account', ['membership', 'permission', 'tier', 'isAdmin', 'organisationTradingStyles']),
			...mapGetters('AccountOrganisationUser', ['userTradingStyle']),

			/**
			 * @name displayLogo
			 * @description Should we display the Air Sourcing logo
			 * @return {Boolean}
			 */
			displayLogo() {
				return this.site.usesMortgageClub;
			},

			/**
			 * @name toolbarDark
			 * @description Should the toolbar display as dark
			 * @return {String|Null}
			 */
			toolbarDark() {
				return this.site.toolbar?.dark;
			},

			/**
			 * @name toolbarColour
			 * @description Return a colour for  our toolbar
			 * @return {String|Null}
			 */
			toolbarColour() {
				return this.site.toolbar?.colour || 'blue-grey-lighten-5';
			},

			/**
			 * @name toolbarImage
			 * @description Return the path to our toolbar image
			 * @return {String|Null}
			 */
			toolbarImage() {
				return this.site.toolbar?.image ? this.$options.filters.urlize(this.site.toolbar.image) : null;
			},

			/**
			 * @name toolbarLogo
			 * @description Return the path to our toolbar image
			 * @return {String|Null}
			 */
			toolbarLogo() {
				return this.site.toolbar?.logoPath;
			},

			/**
			 * @name iconClass
			 * @description Return icon class based on toolbar colour
			 * @return {String|Null}
			 */
			iconClass() {
				return this.toolbarDark ? 'white--text' : 'black--text';
			},

			/**
			 * @name toolbarTier
			 * @description Return tier object with additional display info
			 * @return {Object}
			 */
			toolbarTier() {
				let icon = null;
				let colour = null;
				let tooltip = 'Join Air Club for market-leading procuration fees and unrivalled support. Click here to get started.';

				if (this.tier.id === 'club') {
					tooltip = 'Click here to explore the benefits associated with your Air Club account';
				}

				if (this.tier.id === 'platinum') {
					tooltip = 'Click here to explore the benefits associated with your Air Club account';
				}

				if (this.tier.id === 'elite') {
					tooltip = 'Click here to explore the benefits associated with your Air Club account';
				}

				if (this.tier.id === 'select') {
					icon = 'mdi-network';
					colour = 'success';
					tooltip = `Status: Active`;
					if (this.membership('select').bypass) {
						icon = 'mdi-network-off';
						colour = 'red accent-4';
						tooltip = `Status: Inactive`;
					}
				}

				return { ...this.tier, icon, colour, tooltip };
			},

			showMembershipTier() {
				return this.site.usesMortgageClub && !this.isAdmin && (!this.factfind || this.tier.id === 'select');
			}
		},

		created() {
			EventBus.$on('air-select-disabled', this.setAirSelectDisabled);
			EventBus.$on('account-takeover', this.openAccountTakeover);
			this.handleSelectTradingStyle();
		},

		beforeDestroy() {
			EventBus.$off('air-select-disabled', this.setAirSelectDisabled);
		},

		methods: {
			...mapActions('Account', ['logout']),
			...mapMutations('AccountOrganisationUser', ['setTradingStyle']),

			/**
			 * @debounce @name handleSelectTradingStyle
			 * @description Deal with selected trading style and dialog box
			 */
			handleSelectTradingStyle() {
				if (this.site.tradingStylePanels) {
					// If not an admin with impersonation or admin who is impersonating
					if (
						(!this.permission('ui.account.impersonate.system').read || this.impersonator.id) &&
						this.organisation?.organisationTradingStyles?.length &&
						!this.userTradingStyle
					) {
						// If organisation possesses trading styles and one isn't selected in memory then open dialog to prompt user to select trading style
						const activeTradingStyle = this.organisation.organisationTradingStyles.find((ots) => ots.active);
						if (activeTradingStyle) {
							this.setTradingStyle({
								userId: this.user.id,
								organisationTradingStyleId: activeTradingStyle.id,
								name: activeTradingStyle.name,
								fca: activeTradingStyle.fca || null
							});
						}
						// Trading Style not yet selected
						else {
							this.openSelectTradingStyle();
						}
					}
				}
			},

			/**
			 * @debounce @name openAccountTakeover
			 * @description Open the account takeover box
			 */
			openAccountTakeover: debounce(function() {
				this.$refs.impersonation.open();
			}, 200),

			/**
			 * @debounce @name openContactBox
			 * @description Open the contact box
			 */
			openContactBox: debounce(function() {
				this.$refs.contact.open();
			}, 200),

			/**
			 * @debounce @name openSelectTradingStyleClick
			 * @description Open the trading style dialog with a click
			 */
			openSelectTradingStyleClick: debounce(function() {
				this.openSelectTradingStyle(true);
			}, 200),

			/**
			 * @debounce @name openSelectTradingStyle
			 * @description Open the trading style dialog
			 */
			openSelectTradingStyle: debounce(function(click) {
				this.$refs.selectTradingStyle.open(click ?? false);
			}, 200),

			/**
			 * @debounce @name openTierDialog
			 * @description Open the Air Select box
			 */
			openTierDialog: debounce(function() {
				this.$refs.tier.open();
			}, 200),

			/**
			 * @debounce @name logout
			 * @description Log out of the system
			 */
			openLogout: debounce(function() {
				this.$refs.confirm
					.open('Log out', 'Are you sure you wish to log out of your account?')
					.then((confirm) => {
						if (confirm) {
							return this.logout();
						}
					})
					.catch(() => {});
			}, 200),

			/**
			 * @name setAirSelectDisabled
			 * @description Set air select disabled
			 * @param {Boolean} disabled
			 */
			setAirSelectDisabled(disabled) {
				this.tierDisplayDisabled = disabled;
			}
		}
	};
</script>

<style scoped lang="scss">
	//https://codepen.io/shshaw/pen/RwJwJJx
	.border-animation {
		--border-size: 2px;
		--border-angle: 0turn;
		background-image: conic-gradient(from var(--border-angle), #fff, #fff 50%, #fff), conic-gradient(from var(--border-angle), transparent 20%, #1b78d8, #470048);
		background-size: calc(100% - (var(--border-size) * 2)) calc(100% - (var(--border-size) * 2)), cover;
		background-position: center center;
		background-repeat: no-repeat;

		animation: bg-spin 2.25s linear infinite;
		@keyframes bg-spin {
			to {
				--border-angle: 1turn;
			}
		}

		&:hover {
			animation-play-state: paused;
		}
	}

	@property --border-angle {
		syntax: '<angle>';
		inherits: true;
		initial-value: 0turn;
	}
</style>
